<img src="assets/img/EPSolutely_Logo.jpg" class="w-100"/>

<div class="container-fluid">
    <div class="row">
        <div class="col">
            <h4 class="my-3 mb-3" style="border-bottom: 2px solid #888">
                <i class="fa-solid fa-sack-xmark me-2"></i>Säcke abholen lassen
            </h4>

            <form [formGroup]="form">
                <div class="mb-2">
                    <label>Firmenname</label>
                    <input type="text" class="form-control" formControlName="company_name">
                </div>
                <div class="mb-2">
                    <label>Kontaktdaten</label>
                    <input type="text" class="form-control" formControlName="contact_name" placeholder="Name">
                    <input type="text" class="form-control" formControlName="contact_phone" placeholder="Telefonnummer">
                </div>
                <div class="mb-2">
                    <label>Anzahl Säcke</label>
                    <input type="number" max="99" class="form-control" formControlName="bags">
                </div>
                <div class="mb-2">
                    <label>PLZ und Ort</label>
                    <input formControlName="locationSearch"
                           typeaheadOptionField="zipName"
                           class="form-control"
                           placeholder="Bitte nach PLZ oder Ort suchen"
                           [typeahead]="locations"
                           (typeaheadOnSelect)="setLocation($event)">
                </div>
                <div class="mb-2">
                    <label>Adresse</label>
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="street" placeholder="Straße" style="width: 60%">
                        <input type="number" class="form-control" formControlName="street_number" placeholder="Hausnummer" style="width: 40%">
                    </div>
                    <input type="text" class="form-control" formControlName="address_info" placeholder="Adresszusatz">
                </div>

                <ng-container *ngIf="form.value.id_location">
                    <ng-container *ngIf="form.value.bags >= 10">
                        <div class="mb-2">
                            <label>Präferiertes Abholdatum</label>
                            <input type="date" class="form-control" formControlName="date_pickup_preferred" [min]="minDate" [max]="maxDate">
                        </div>
                        <div class="mb-2">
                            <label>Kommentar</label>
                            <input type="text" class="form-control" formControlName="comment_creator">
                        </div>
                    </ng-container>

                    <ng-container *ngIf="form.value.bags < 10 && collectionPoint">
                        <div class="alert alert-warning d-flex align-items-center">
                            <i class="fa-solid fa-info-circle me-2 fa-2x"></i>
                            <div>
                                <span class="me-2">Die Abholung von weniger als 10 Säcken ist nicht möglich. Bitte bringen Sie die Säcke zur nächsten Sammelstelle:</span>
                                <span class="fw-bold">{{ collectionPoint.zip}} {{ collectionPoint.city}}, {{ collectionPoint.street }}</span>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <div class="mb-2">
                    <label>Anhänge</label>
                    <div class="d-grid">
                        <button type="button" class="btn btn-secondary" (click)="triggerFileInput()">Foto, Video oder andere Datei hochladen</button>
                        <input type="file" #fileInput class="d-none" multiple (change)="handleInputChange($event)">
                    </div>

                    <div class="table-responsive">
                        <table *ngIf="files.length > 0" class="table table-sm table-hover table-files bg-white mt-2 mb-0">
                            <thead>
                            <tr>
                                <th style="width: 45px"></th>
                                <th style="width: 150px">Vorschau</th>
                                <th>Name</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let file of files">
                                <td>
                                    <button type="button" class="btn btn-danger btn-sm" (click)="removeFile(file)"><i class="fa-solid fa-trash"></i></button>
                                </td>
                                <td>
                                    <img class="img-fluid" *ngIf="file.type.startsWith('image')" [src]="file.data">
                                    <video *ngIf="file.type.startsWith('video')" controls [src]="file.data"></video>
                                    <span *ngIf="!file.type.startsWith('image') && !file.type.startsWith('video')">Nicht verfügbar</span>
                                </td>
                                <td>
                                    {{ file.name }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="text-muted mt-1" *ngIf="files.length == 0">Keine Anhänge vorhanden</div>
                </div>
            </form>

            <div class="d-grid mb-3">
                <button type="button" class="btn btn-lg btn-success mt-2" [disabled]="form.invalid || saving" (click)="save()">
                    Senden
                    <i *ngIf="saving" class="fa-solid fa-spinner fa-spin-pulse"></i>
                </button>
            </div>
        </div>
    </div>
</div>
