<img src="assets/img/EPSolutely_Logo.jpg" class="w-100"/>

<div class="container-fluid">
    <div class="row">
        <div class="col">
            <h4 class="my-3 mb-3" style="border-bottom: 2px solid #888">
                <i class="fa-solid fa-sack-xmark me-2"></i>Säcke bestellen
            </h4>

            <form [formGroup]="form">
                <div class="mb-2">
                    <label>Firmenname</label>
                    <input type="text" class="form-control" formControlName="company_name">
                </div>
                <div class="mb-2">
                    <label>Kontaktdaten</label>
                    <input type="text" class="form-control" formControlName="contact_name" placeholder="Name">
                    <input type="text" class="form-control" formControlName="contact_phone" placeholder="Telefonnummer">
                </div>
                <div class="mb-2">
                    <label>Anzahl Säcke</label>
                    <input type="number" max="99" class="form-control" formControlName="bags">
                </div>
                <div class="mb-2">
                    <label>PLZ und Ort</label>
                    <input formControlName="locationSearch"
                           typeaheadOptionField="zipName"
                           class="form-control"
                           placeholder="Bitte nach PLZ oder Ort suchen"
                           [typeahead]="locations"
                           (typeaheadOnSelect)="setLocation($event)">
                </div>
                <div class="mb-2">
                    <label>Adresse</label>
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="street" placeholder="Straße" style="width: 60%">
                        <input type="number" class="form-control" formControlName="street_number" placeholder="Hausnummer" style="width: 40%">
                    </div>
                    <input type="text" class="form-control" formControlName="address_info" placeholder="Adresszusatz">
                </div>
                <!--<div class="mb-2">
                    <label>Kommentar</label>
                    <input type="text" class="form-control" formControlName="comment_creator">
                </div>-->
            </form>

            <div class="d-grid mb-3">
                <button type="button" class="btn btn-lg btn-success mt-2" [disabled]="form.invalid || saving" (click)="save()">
                    Senden
                    <i *ngIf="saving" class="fa-solid fa-spinner fa-spin-pulse"></i>
                </button>
            </div>
        </div>
    </div>
</div>
