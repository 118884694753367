import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AccessService} from './services/access.service';
import {LocalStorageService} from './services/local-storage.service';
import {DataService} from './services/data.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {

    constructor(
        private accessService: AccessService,
        private router: Router,
        private localStorageService: LocalStorageService,
        private dataService: DataService,
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const token = this.localStorageService.getItem('token');

        if (token) {
            this.accessService.token = token;
            return this.dataService.request("User/get").pipe(map(response => {
                if (response.error) {
                    this.router.navigate(['/login']);
                    return false;
                } else {
                    this.accessService.user = response.user;
                    return true;
                }
            }));
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }

}
