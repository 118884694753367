import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {DataService} from "../../../services/data.service";
import Swal from "sweetalert2";
import moment from 'moment';
import {TypeaheadMatch} from "ngx-bootstrap/typeahead";

@Component({
    selector: 'app-report-create',
    templateUrl: './report-create.component.html',
    styleUrls: ['./report-create.component.scss']
})
export class ReportCreateComponent implements OnInit {

    @ViewChild('fileInput') fileInput: ElementRef;

    form!: UntypedFormGroup;
    locations: any[] = [];
    files: any[] = [];
    minDate: string;
    maxDate: string;

    saving = false;

    collectionPoint: any;

    constructor(
        private fb: UntypedFormBuilder,
        private dataService: DataService,
    ) {
    }

    ngOnInit(): void {
        this.minDate = moment().format("YYYY-MM-DD");
        this.maxDate = moment().add(1, 'month').format("YYYY-MM-DD");

        this.form = this.fb.group({
            company_name: ['', Validators.required],
            contact_name: ['', Validators.required],
            contact_phone: ['', Validators.required],
            bags: [null, Validators.required],
            id_location: ['', Validators.required],
            street: ['', Validators.required],
            street_number: ['', Validators.required],
            address_info: [''],
            date_pickup_preferred: [null],
            comment_creator: [''],

            locationSearch: [''],
        });

        this.dataService.request('Location/listForSelection').subscribe(response => {
            this.locations = response.locations;

            this.locations.forEach(location => {
                location.zipName = location.zip + ' ' + location.city;
            })
        });

        // watch bags changes
        this.form.get('bags').valueChanges.subscribe(value => {
            if (value && this.form.get('id_location').value) {
                this.getCollectionPoint();
            }
        });

        // watch location changes
        this.form.get('id_location').valueChanges.subscribe(value => {
            if (value && this.form.get('bags').value) {
                this.getCollectionPoint();
            }
        });
    }

    getCollectionPoint(): void {
        this.dataService.request('CollectionPoint/getForCreation', {
            id_location: this.form.get('id_location').value,
        }).subscribe(response => {
            this.collectionPoint = response.collectionPoint;
        });
    }

    handleInputChange(e): void {
        for (let i = 0; i < e.target.files.length; i++) {
            let reader = new FileReader();

            reader.onload = (progressEvent) => {
                this.files.push({
                    name: e.target.files[i].name,
                    size: e.target.files[i].size,
                    type: e.target.files[i].type,
                    data: progressEvent.target.result,
                    id: null,
                });
                if (i == e.target.files.length - 1) {
                    this.fileInput.nativeElement.value = '';
                }
            };

            reader.readAsDataURL(e.target.files[i]);
        }
    }

    save(): void {
        /*if (this.files.length === 0) {
            Swal.fire({
                title: 'Sicher?',
                text: 'Vorfall ohne Anhang speichern?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Ja',
                cancelButtonText: 'Nein'
            }).then(result => {
                if (result.isConfirmed) {
                    this.saveNow();
                }
            });
        } else {*/
            this.saveNow();
        //}
    }

    saveNow(): void {
        this.saving = true;
        this.dataService.request('Report/save', {
            report: this.form.getRawValue(),
            files: this.files,
        }).subscribe(response => {
            this.saving = false;

            if (response.error) {
                Swal.fire({
                    title: 'Fehler!',
                    text: 'Es gab ein Problem beim Speichern der Meldung',
                    icon: 'error',
                })
            } else {
                this.form.reset();
                this.files = [];

                Swal.fire({
                    title: 'Vielen Dank!',
                    text: 'Die Meldung wurde mit der Nummer ' + response.number + ' gespeichert!',
                    icon: 'success',
                })
            }
        });
    }

    triggerFileInput() {
        this.fileInput.nativeElement.click();
    };

    removeFile(file: any) {
        this.files.splice(this.files.indexOf(file), 1);
    }

    setLocation($event: TypeaheadMatch<any>) {
        this.form.patchValue({
            id_location: $event.item.id,
        });
    }
}
