import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {DataService} from "../../../services/data.service";
import Swal from "sweetalert2";
import {TypeaheadMatch} from "ngx-bootstrap/typeahead";

@Component({
    selector: 'app-order-create',
    templateUrl: './order-create.component.html',
    styleUrls: ['./order-create.component.scss']
})
export class OrderCreateComponent implements OnInit {

    form!: UntypedFormGroup;
    locations: any[] = [];

    saving = false;

    constructor(
        private fb: UntypedFormBuilder,
        private dataService: DataService,
    ) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            company_name: ['', Validators.required],
            contact_name: ['', Validators.required],
            contact_phone: ['', Validators.required],
            bags: ['', Validators.required],
            id_location: ['', Validators.required],
            street: ['', Validators.required],
            street_number: ['', Validators.required],
            address_info: [''],
            comment_creator: [''],

            locationSearch: [''],
        });

        this.dataService.request('Location/listForSelection').subscribe(response => {
            this.locations = response.locations;

            this.locations.forEach(location => {
                location.zipName = location.zip + ' ' + location.city;
            })
        });
    }

    save(): void {
        this.saving = true;
        this.dataService.request('Order/save', {
            order: this.form.getRawValue(),
        }).subscribe(response => {
            this.saving = false;

            if (response.error) {
                Swal.fire({
                    title: 'Fehler!',
                    text: 'Es gab ein Problem beim Speichern der Sackbestellung',
                    icon: 'error',
                })
            } else {
                this.form.reset();

                Swal.fire({
                    title: 'Vielen Dank!',
                    text: 'Die Sackbestellung wurde mit der Nummer ' + response.number + ' gespeichert!',
                    icon: 'success',
                })
            }
        });
    }

    setLocation($event: TypeaheadMatch<any>) {
        this.form.patchValue({
            id_location: $event.item.id,
        });
    }
}
