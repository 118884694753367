import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from "./auth.guard";
import {ResetPasswordComponent} from "./reset-password/reset-password.component";
import {ReportCreateComponent} from "./page/report/report-create/report-create.component";
import {OrderCreateComponent} from "./page/order/order-create/order-create.component";

const routes: Routes = [
    {
        path: 'admin',
        loadChildren: () => import('./page/page.module').then(m => m.PageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'create',
        component: ReportCreateComponent,
    },
    {
        path: 'bestellung',
        component: OrderCreateComponent,
    },
    {
        path: 'abholung',
        component: ReportCreateComponent,
    },
    {
        path: '',
        redirectTo: 'abholung', pathMatch: 'full',
    },
    {
        path: 'reset-password/:hash',
        component: ResetPasswordComponent,
    },
    {
        path: 'error', loadChildren: () => import('./errors/errors.module').then(m => m.ErrorsModule),
    },
    {
        path: '**',
        loadChildren: () => import('./errors/errors.module').then(m => m.ErrorsModule),
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRouting {
}
