import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppRouting} from './app.routing';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DatePipe, registerLocaleData} from '@angular/common';
import localeDE from '@angular/common/locales/de';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {PipesModule} from "./pipes/pipes.module";
import {ToastrModule} from "ngx-toastr";
import {ReactiveFormsModule} from "@angular/forms";
import {ReportCreateComponent} from "./page/report/report-create/report-create.component";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {OrderCreateComponent} from "./page/order/order-create/order-create.component";

registerLocaleData(localeDE);

@NgModule({
    declarations: [
        AppComponent,
        ResetPasswordComponent,
        ReportCreateComponent,
        OrderCreateComponent,
    ],
    imports: [
        BrowserModule,
        AppRouting,
        HttpClientModule,
        ToastrModule.forRoot(),
        BrowserAnimationsModule,
        PipesModule,
        ReactiveFormsModule,
        TypeaheadModule,
    ],
    providers: [
        DatePipe,
        {provide: LOCALE_ID, useValue: 'de'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
